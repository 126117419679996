

// requestAnimationFrame polyfill by Erik Möller. fixes from Paul Irish and Tino Zijdel
'use strict';

(function () {
    var b = 0;var c = ['ms', 'moz', 'webkit', 'o'];for (var a = 0; a < c.length && !window.requestAnimationFrame; ++a) {
        window.requestAnimationFrame = window[c[a] + 'RequestAnimationFrame'];window.cancelAnimationFrame = window[c[a] + 'CancelAnimationFrame'] || window[c[a] + 'CancelRequestAnimationFrame'];
    }if (!window.requestAnimationFrame) {
        window.requestAnimationFrame = function (h, e) {
            var d = new Date().getTime();var f = Math.max(0, 16 - (d - b));var g = window.setTimeout(function () {
                h(d + f);
            }, f);b = d + f;return g;
        };
    }if (!window.cancelAnimationFrame) {
        window.cancelAnimationFrame = function (d) {
            clearTimeout(d);
        };
    }
})();