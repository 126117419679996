/*------------------------------------*\
    #START
\*------------------------------------*/

/* HERE WE DEFINE THE WASABIWEB GLOBAL OBJECT, ANY VANILLA JS WE WANT TO EXECUTE IMMEDIATLEY BEFORE JQUERY IS LOADED GOES HERE,
   AS SUCH YOU CANNOT USE THE JQUERY($) FUNCTION IN THIS FILE.
*/

// declare wasabiweb global object -- all would be global variables should get bound to this object,
// to ensure we don't pollute the global scope.
'use strict';

var WASABIWEB = {};

(function () {

    'use strict';

    // optimise resize event with requestAnimationFrame
    WASABIWEB.optimizedResize = (function () {
        var callbacks = [],
            running = false;
        // fired on resize event
        function resize() {
            if (!running) {
                running = true;
                window.requestAnimationFrame(runCallbacks);
            }
        }
        // run the actual callbacks
        function runCallbacks() {
            callbacks.forEach(function (callback) {
                callback();
            });
            running = false;
        }
        // adds callback to loop
        function addCallback(callback) {
            if (callback) {
                callbacks.push(callback);
            }
        }

        return {
            // initalize resize event listener
            init: function init(callback) {
                window.addEventListener('resize', resize);
                addCallback(callback);
            }
        };
    })();

    WASABIWEB.calcNavbarCollapse = function (set) {
        // calculate navbar breakpoint
        // cache navbar, navbar-nav
        var elements = document.querySelectorAll('.js-navbar'),
            navbars = [],
            pageContent = document.querySelector('#mainContent'),
            offset = 68,
            // site-padding width + any additional offset.
        elementsArr = [].slice.call(elements);
        elementsArr.forEach(function (el, i) {
            navbars.push({
                navbar: el,
                navbarWrap: el.parentNode,
                isPrimaryNav: false,
                isCollapsed: false,
                navbarNav: el.querySelector('.js-navbar__nav'),
                nonNavContent: el.querySelectorAll('.js-non-nav-content'),
                nonNavContentWidth: 0
            });
            if (navbars[i].navbarWrap.className.match(/js\-primary\-nav/)) {
                navbars[i].isPrimaryNav = true;
            }
            for (var k = 0, j = navbars[i].nonNavContent.length; k < j; k++) {
                navbars[i].nonNavContentWidth += navbars[i].nonNavContent[k].offsetWidth;
            }
        });

        function execute() {
            document.body.className = document.body.className.replace(/(?:^|\s)site\-header\-collapsed(?!\S)/, '');
            for (var i = 0, l = navbars.length; i < l; i++) {
                navbars[i].navbar.className = navbars[i].navbar.className.replace(/(?:^|\s)navbar\-\-collapsed(?!\S)/, '');
                navbars[i].navbarWrap.className = navbars[i].navbarWrap.className.replace(/(?:^|\s)navbar\-child\-collapsed(?!\S)/, '');
                navbars[i].navbar.className = navbars[i].navbar.className.replace(/(?:^|\s)navbar\-\-expanded(?!\S)/, '');
                navbars[i].navbarWrap.className = navbars[i].navbarWrap.className.replace(/(?:^|\s)navbar\-child\-expanded(?!\S)/, '');
                if (navbars[i].navbar.offsetWidth < navbars[i].navbarNav.offsetWidth + navbars[i].nonNavContentWidth + offset) {
                    navbars[i].navbar.className += ' navbar--collapsed';
                    navbars[i].navbarWrap.className += ' navbar-child-collapsed';
                    if (navbars[i].isPrimaryNav) {
                        document.body.className += ' site-header-collapsed';
                    }
                    navbars[i].isCollapsed = true;
                } else {
                    navbars[i].navbar.className += ' navbar--expanded';
                    navbars[i].navbarWrap.className += ' navbar-child-expanded';
                    navbars[i].isCollapsed = false;
                    if (navbars[i].isPrimaryNav) {

                        document.body.className = document.body.className.replace(/(?:^|\s)site\-nav\-open(?!\S)/, '');
                        document.body.className = document.body.className.replace(/(?:^|\s)js\-site\-nav\-open(?!\S)/, '');
                        document.body.style.paddingRight = 0;
                        navbars[i].navbar.style.right = 0;
                        if (pageContent) {
                            pageContent.className = pageContent.className.replace(/(?:^|\s)site\-nav\-open(?!\S)/, '');
                            pageContent.className = pageContent.className.replace(/(?:^|\s)js\-site\-nav\-open(?!\S)/, '');
                            navbars[i].navbar.className = navbars[i].navbar.className.replace(/(?:^|\s)open(?!\S)/, '');
                        }
                    }
                }
            }
        }

        window.requestAnimationFrame(execute);
        if (set) {
            WASABIWEB.optimizedResize.init(function () {
                execute();
            });
        }
        window.onload = execute();
        return this;
    };

    WASABIWEB.fadeIn = function () {
        document.documentElement.className += ' fade-in';
        return this;
    };

    WASABIWEB.isTouch = (function () {
        return (/Android|iPhone|iPad|iPod|BlackBerry|Windows Phone/i.test(navigator.userAgent || navigator.vendor || window.opera)
        );
    })();

    (function () {
        if (WASABIWEB.isTouch) {
            document.body.className += ' touch';
        }
    })();

    (function () {
        // This allows us to get the scrollbar width for the site.
        // Create the measurement node
        var scrollDiv = document.createElement('div');
        scrollDiv.className = 'scrollbar-measure';
        document.body.appendChild(scrollDiv);

        // Get the scrollbar width
        var scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
        WASABIWEB.scrollbarWidth = scrollbarWidth;

        // Delete the DIV
        document.body.removeChild(scrollDiv);

        var iOS = navigator.userAgent.match(/(iPad|iPhone|iPod)/g) ? true : false;
        // add class to body if IOS
        if (iOS) {
            WASABIWEB.iOS = true;
            document.body.className += ' iOS';
        }
        if (navigator.userAgent.match(/(iPad|iPhone);.*CPU.*OS 7_\d/i)) {
            document.body.className += ' iOS7';
        }
    })();

    WASABIWEB.calcNavbarCollapse().fadeIn();
})();